<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">บัญชีลูกหนี้</h4>
      </div>

     <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="filterData.search"
              label="พิมพ์คำค้นหา เช่น ชื่อลูกค้า เลขที่ใบเสร็จ"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="3">
            <v-menu
              ref="menuFilterDate"
              v-model="menuFilterDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDateFormatted"
                  label="วันที่ชำระเงิน"
                  placeholder="ระบุวันที่ชำระเงิน"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuFilterDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterData.date"
                range
                no-title
                @change="menuFilterDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsStatus"
              v-model="filterData.status"
              item-text="name"
              item-value="name"
              label="สถานะจ่ายเงิน"
              placeholder="เลืือกสถานะจ่ายเงิน"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="data"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.bill_bill_no="{ item }">{{item.bill_bill_no || '-'}}</template>
      <template v-slot:item.bill_customer="{ item }"><div class="text-start">{{item.bill_customer.name}}</div></template>
      <template v-slot:item.bill_totalprice="{ item }"><div class="text-end">{{formatThaiBaht(item.bill_totalprice)}}</div></template>
      <template v-slot:item.bill_paidprice="{ item }"><div class="text-end">{{formatThaiBaht(item.bill_paidprice)}}</div></template>
      <template v-slot:item.bill_totalpaid="{ item }"><div class="text-end">{{formatThaiBaht(item.bill_totalpaid)}}</div></template>
      <template v-slot:item.bill_remain="{ item }"><div class="text-end">{{formatThaiBaht(item.bill_totalprice - item.bill_totalpaid)}}</div></template>
      <template v-slot:item.bill_paidby="{ item }">
        {{
          item.bill_paidby.type === 'credit' ? 'บัตรเครดิต'
          : item.bill_paidby.type === 'qrcode' ? 'คิวอาร์โค้ด'
          : item.bill_paidby.type === 'transfer' ? 'โอนเงิน'
          : 'เงินสด'
        }}
      </template>
      <template v-slot:item.bill_vatprice="{ item }"><div :class="item.bill_paidby.type === 'credit' && 'text-end'">{{calVatPaidByCredit(item)}}</div></template>
      <template v-slot:item.bill_date="{ item }">{{moment(item.bill_date).add(543, 'year').format('DD/MM/YYYY')}}</template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="printreceivable(item)"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      loaderAdd: false,
      data: [{
        _id: '000',
        no: 1,
        bill_no: 'RE0001',
        bill_createdby: {
          name: 'Admin_WG001'
        },
        bill_customer: {
          code: 'xxxx123456789',
          belongto: 'เป็นเจ้าของคอร์ส',
          bloodgroup: 'B',
          idcardnumber: 'xxxxxxxxxxxxxxxxxxx',
          name: 'สุจิตตรา ศรีนาม',
          fullname: 'นางสาว สุจิตตรา ศรีนาม',
          nickname: 'บี',
          birthdate: '1989-05-16',
          age: 0,
          gender: 'female',
          occupation: 'ธุรกิจส่วนตัว',
          phonenumber: '0912345678'
        },
        bill_billstatus: 'บันทึก',
        bill_date: moment(),
        bill_type: '',
        bill_paidstatus: 'รอจ่าย',
        bill_totalprice: 9200,
        bill_paidprice: 0,
        bill_totalpaid: 0,
        bill_lesson: 1,
        bill_paidby: {
          customername: 'สุจิตตรา ศรีนาม',
          type: 'credit'
        }
      }, {
        _id: '001',
        no: 2,
        bill_no: 'RE0002',
        bill_createdby: {
          name: 'Admin_WG001'
        },
        bill_customer: {
          code: 'xxxx123456789',
          belongto: 'เป็นเจ้าของคอร์ส',
          bloodgroup: 'B',
          idcardnumber: 'xxxxxxxxxxxxxxxxxxx',
          name: 'นิรมล พลบูรณ์',
          fullname: 'นางสาว นิรมล พลบูรณ์',
          nickname: 'บี',
          birthdate: '1989-05-16',
          age: 0,
          gender: 'female',
          occupation: 'ธุรกิจส่วนตัว',
          phonenumber: '0912345678'
        },
        bill_billstatus: 'ปกติ',
        bill_date: moment(),
        bill_type: 'ผ่อนชำระ',
        bill_paidstatus: 'ค้างจ่าย',
        bill_totalprice: 4500,
        bill_paidprice: 2000,
        bill_totalpaid: 4000,
        bill_lesson: 1,
        bill_paidby: {
          customername: 'นิรมล พลบูรณ์',
          type: 'credit'
        }
      }, {
        _id: '002',
        no: 3,
        bill_no: 'RE0003',
        bill_createdby: {
          name: 'Admin_WG001'
        },
        bill_customer: {
          code: 'xxxx123456789',
          belongto: 'เป็นเจ้าของคอร์ส',
          bloodgroup: 'B',
          idcardnumber: 'xxxxxxxxxxxxxxxxxxx',
          name: 'ศิริพร ทองอยู่',
          fullname: 'นางสาว ศิริพร ทองอยู่',
          nickname: 'บี',
          birthdate: '1989-05-16',
          age: 0,
          gender: 'female',
          occupation: 'ธุรกิจส่วนตัว',
          phonenumber: '0912345678'
        },
        bill_billstatus: 'ยกเลิก',
        bill_date: moment(),
        bill_type: 'ชำระเต็ม',
        bill_paidstatus: 'จ่ายครบ',
        bill_totalprice: 7000,
        bill_paidprice: 7000,
        bill_totalpaid: 7000,
        bill_lesson: 1,
        bill_paidby: {
          customername: 'นิรมล พลบูรณ์',
          type: 'cash'
        }
      }, {
        _id: '003',
        no: 4,
        bill_no: 'RE0004',
        bill_createdby: {
          name: 'Admin_WG001'
        },
        bill_customer: {
          code: 'xxxx123456789',
          belongto: 'เป็นเจ้าของคอร์ส',
          bloodgroup: 'B',
          idcardnumber: 'xxxxxxxxxxxxxxxxxxx',
          name: 'พรสุดา คงศิริ',
          fullname: 'นางสาว พรสุดา คงศิริ',
          nickname: 'บี',
          birthdate: '1989-05-16',
          age: 0,
          gender: 'female',
          occupation: 'ธุรกิจส่วนตัว',
          phonenumber: '0912345678'
        },
        bill_billstatus: 'แก้ไข',
        bill_date: moment(),
        bill_type: 'ชำระเต็ม',
        bill_paidstatus: 'จ่ายครบ',
        bill_totalprice: 6500,
        bill_paidprice: 6500,
        bill_totalpaid: 6500,
        bill_lesson: 1,
        bill_paidby: {
          customername: 'พรสุดา คงศิริ',
          type: 'cash'
        }
      }],
      itemsStatus: [{
        _id: '00000',
        name: 'ค้างจ่าย'
      }, {
        _id: '00001',
        name: 'จ่ายครบ'
      }, {
        _id: '00002',
        name: 'ยกเลิก'
      }],
      menuSelectDate: false,
      filterData: {
        date: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
        search: '',
        status: ''
      },
      menuFilterDate: false
    }
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 2000)
    },
    formatThaiBaht (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    viewservice (item) {
      console.log('view: ', item)
    },
    printreceivable (item) {
      console.log('print: ', item)
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
        self.$router.push({ name: 'ListPayScreen', params: { dataItems: item } })
      }, 300)
    },
    addOutcomeData () {
      console.log('Add Outcome')
    },
    calVatPaidByCredit (item) {
      const self = this
      var sum = 0
      var sumreturn
      if (item.bill_paidby.type === 'credit') {
        sum = item.bill_paidprice * 0.03
        sumreturn = self.formatThaiBaht(sum)
      } else {
        sum = 0
        sumreturn = '-'
      }
      return sumreturn
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          value: 'no',
          sortable: false
        }, {
          text: 'เลขที่ใบเสร็จ',
          align: 'center',
          value: 'bill_no',
          sortable: false
        }, {
          text: 'ชื่อลูกค้า',
          align: 'center',
          value: 'bill_customer',
          sortable: false
        }, {
          text: 'ยอดสุทธิ',
          align: 'center',
          value: 'bill_totalprice',
          sortable: false
        }, {
          text: 'งวดที่',
          align: 'center',
          value: 'bill_lesson',
          sortable: false
        }, {
          text: 'ยอดชำระ',
          align: 'center',
          value: 'bill_paidprice',
          sortable: false
        }, {
          text: 'ยอดรวมชำระ',
          align: 'center',
          value: 'bill_totalpaid',
          sortable: false
        }, {
          text: 'ยอดค้าง',
          align: 'center',
          value: 'bill_remain',
          sortable: false
        }, {
          text: 'สถานะการจ่าย',
          align: 'center',
          value: 'bill_billstatus',
          sortable: false
        }, {
          text: 'ช่องทางการชำระ',
          align: 'center',
          value: 'bill_paidby',
          sortable: false
        }, {
          text: 'ค่าธรรมเนียม',
          align: 'center',
          value: 'bill_vatprice',
          sortable: false
        }, {
          text: 'วันที่ชำระ',
          align: 'center',
          value: 'bill_date',
          sortable: false
        }, {
          text: 'ใบเสร็จ/ใบกำกับ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedFilterDateFormatted () {
      const self = this
      // return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
      return `${self.filterData.date[0] ? moment(self.filterData.date[0]).add(543, 'year').format('DD/MM/YYYY') : moment().add(543, 'year').format('DD/MM/YYYY')} ถึง ${self.filterData.date[1] ? moment(self.filterData.date[1]).add(543, 'year').format('DD/MM/YYYY') : moment(self.filterData.date[0]).add(543, 'year').format('DD/MM/YYYY')}`
    }
  }
}
</script>
